<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="primary--border" outlined>
                    <v-tabs
                        v-model="tab"
                        icons-and-text
                    >
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-1">
                            Daily Assignment
                            <v-icon>all_inbox</v-icon>
                        </v-tab>

                        <v-tab href="#tab-2">
                            Assignment not checked teacher list
                            <v-icon>all_inbox</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item
                                :key="1"
                                value="tab-1"
                        >
                            <v-card flat>
                                <day-wise-student-assignment></day-wise-student-assignment>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item
                                :key="2"
                                value="tab-2"
                        >
                            <v-card flat>
                                <assignment-not-checked-teacher-list></assignment-not-checked-teacher-list>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import DayWiseStudentAssignment from "./pages/DayWiseStudentAssigment";
    import AssignmentNotCheckedTeacherList from "./pages/AssignmentNotCheckedTeacherList";


    const NepaliDate = require('nepali-date');


    export default {
        components: {DayWiseStudentAssignment, AssignmentNotCheckedTeacherList},

        data: () => ({
            tab: null,
        }),

        computed: {
            ...mapState(['batch']),
        },
        mounted() {
        },
        watch: {},
        methods: {}
    }
</script>
<style lang="scss">

    .table-wrapper {
        overflow-x: scroll;
        overflow-y: visible;
    }

    .table-wrapper table {
        border: 1px solid black;
        border-collapse: collapse;
    }

    .table-wrapper table tr td, .table-wrapper table tr th {
        padding: 5px 20px;
        border: 1px solid black;
        width: 100px;
        text-align: center;
    }

    .sat {
        background: #ff5252;
        color: white;
    }

    .saturday {
        background-color: red;
        color: white;
        /*color: #ff5252;*/
    }

    .present {
        /*color: #4caf50;*/
    }

    .absent {
        /*color: #ff5252;*/
    }

    .late {
        font-size: 8px;
        /*color:#fb8c00*/
    }

    .leave {
        font-size: 8px;
        /*color: #1976d2*/
    }

    .event_day {
        color: white;
    }

    .event_color {
        background: #4caf50;
    }

    td.event_day.tootltip {
        cursor: pointer;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    td.event_day.tootltip:hover .tooltiptext {
        visibility: visible !important;
        color: white;
    }

    .tooltiptext {
        visibility: hidden;
        width: 500px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: relative;
        z-index: 9999;
    }

    .cell_bottom {
        border: white !important;
    }

    /*th:first-child {*/
    /*position: fixed;*/
    /*}*/
    /*th:nth-child {*/
    /*right: 5px;*/
    /*}*/

</style>